<template>
    <div class="annual-rreceivables">
        <div class="annual-rreceivables-box">
            <!-- 导出excel -->
            <exportDom :dom-id="'#statisticsDom'" :excel-name="'年度应收账款'"></exportDom>
            <div class="clue-statistics-two">
                <el-table
                    id="statisticsDom"
                    :data="tableData"
                    border
                    style="width: 100%"
                    show-summary
                >
                    <el-table-column
                        prop="name"
                        label="销售分公司"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ncys"
                        label="年初应收余额(元)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="xsje"
                        label="年度累计总销售金额(元)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ysje"
                        label="年度累计应收金额(元)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ssje"
                        label="年度累计实收金额(元)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="fpje"
                        label="年度累计已开发票金额(元)"
                        width="160"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="wkje"
                        label="累计尾款金额(元)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ysye"
                        label="年末应收余额(元)"
                    >
                    </el-table-column>
                </el-table>
            </div>
            <div class="body-box">
                <header class="year-body-header">
                    <span>回款方式</span>
                </header>
                <div class="echarts-wrapper">
                    <div class="childSalesVolume-w">
                        <div style="width:100%;height:200px" id="childSalesVolume"></div>
                        <div class="echarts-position-div" :style="{color: selectColorChild}">
                            <p v-if="salesVolumeTextSubText">
                                {{ salesVolumeTextSubText }}%
                            </p>
                            <p>{{ salesVolumeText }}</p>
                        </div>
                    </div>
                    <div class="one-echarts-right">
                        <!-- <div class="one-echarts-right-header">
                        <p>合计(元)：</p><span>{{ flTotalNumber.toFixed(2) }}</span>
                    </div> -->
                        <div class="one-echarts-right-body">
                            <div>
                                <span>合计(元)：</span>
                                <p :title="flTotalNumber.toFixed(2)">
                                    {{ flTotalNumber.toFixed(2) }}
                                </p>
                            </div>
                            <div v-for="(item, index) in hkfsData" :key="index">
                                <span>{{ item.name }}</span>
                                <p :style="{color: echartsColor[index]}" :title="item.je">
                                    {{ item.je }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import exportDom from './../salesStatistics/components/export';
export default {
    components: {
        exportDom,
    },
    data() {
        return {
            tableData: [],
            hkfsData: {},


            flTotalNumber: 0,
            salesVolumeText: '',
            salesVolumeTextSubText: '',
            selectColorChild: '',
            salesVolumeData: [],
            echartsColor: ['#EA8343', '#4CA295', '#4BA2DA', '#3E78C3', '#f2719a', '#fca4bb', '#f59a8f', '#fdb301', '#57e7ec', '#cf9ef1'],


        };
    },
    mounted() {
        this.financialanalysisNdyszk();
        this.financialanalysisHkfs();
    },
    methods: {
        financialanalysisNdyszk() {
            this.$axios
                .get('/interfaceApi/report/financialanalysis/ndyszk?date=')
                .then(res => {
                    this.tableData = res.list;
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
        financialanalysisHkfs() {
            this.$axios
                .get('/interfaceApi/report/financialanalysis/hkfs?date=')
                .then(res => {
                    this.hkfsData = res.list;
                    res.list.forEach(item => {
                        this.flTotalNumber += item.je;
                        if (!item.name) {
                            item.name = '';
                        }
                        const circularData = {
                            name: item.name,
                            value: item.je,
                        };
                        this.salesVolumeData.push(circularData);
                    });
                    const oneDataNumber = this.salesVolumeData[0].value / this.flTotalNumber * 100;
                    this.salesVolumeText = this.salesVolumeData[0].name;
                    this.salesVolumeTextSubText = oneDataNumber.toFixed(1);
                    this.selectColorChild = this.echartsColor[0];
                    this.salesVolumeEcharts();
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
        // 各类合同销售方量统计
        salesVolumeEcharts() {
            const that = this;
            const myChart = this.$echarts.init(document.getElementById('childSalesVolume'));
            const option = {
                color: that.echartsColor,
                legend: {
                    left: 50,
                    top: 30,
                    itemWidth: 8,
                    itemHeight: 8,
                    width: '50',
                },
                title: [{
                    show: false,
                    text: '{val|' + this.salesVolumeTextSubText + '%}\n{name|' + this.salesVolumeText + '}',
                    top: 'center',
                    left: '55%',
                    textStyle: {
                        rich: {
                            name: {
                                fontSize: 14,
                                fontFamily: 'QuartzLTStd',
                                fontWeight: 'normal',
                                color: 'rgb(234, 131, 67)',
                                padding: [0, 5],
                            },
                            val: {
                                fontSize: 18,
                                fontFamily: 'QuartzLTStd',
                                fontWeight: 'normal',
                                color: 'rgb(234, 131, 67)',

                            },
                        },
                    },
                }],
                series: [{
                    type: 'pie',
                    minAngle: 1, // 最小的扇区角度（0 ~ 360）
                    radius: ['60%', '90%'],
                    center: ['60%', '50%'],
                    avoidLabelOverlap: false,
                    itemStyle: { // 图形样式
                        normal: {
                            borderColor: '#ffffff',
                            borderWidth: 6,
                        },
                    },
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            formatter: '{text|{b}}\n{c} ({d}%)',
                            rich: {
                                text: {
                                    color: '#666',
                                    fontSize: 14,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    padding: 8,
                                },
                                value: {
                                    color: '#8693F3',
                                    fontSize: 24,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                },
                            },
                        },
                    },
                    data: that.salesVolumeData,
                }],
            };
            myChart.setOption(option);

            setTimeout(function () {
                myChart.on('mouseover', function (params) {
                    if (params.name === that.salesVolumeData[0].name) {
                        myChart.dispatchAction({
                            type: 'highlight',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.salesVolumeText = params.name;
                        that.salesVolumeTextSubText = params.percent;
                    } else {
                        myChart.dispatchAction({
                            type: 'downplay',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.salesVolumeText = params.name;
                        that.salesVolumeTextSubText = params.percent;
                    }
                    that.selectColorChild = params.color;
                });

            }, 1000);
        },
    },
};

</script>
<style lang='stylus'>
.annual-rreceivables-box
    background #fff
    width 100%
    height 100%
    padding .2rem
    .body-box
        width 100%
        border 1px solid #D7D7D7
        margin-top .2rem
        position relative
        padding-bottom .2rem
        .year-body-header
            display flex
            justify-content space-between
            margin .2rem
            span:first-child
                color #333333
                font-weight 800
                font-size .18rem
                &:before
                    content '1'
                    width .06rem
                    height .2rem
                    color #1577D2
                    background #1577D2
                    margin-right .1rem
        .echarts-wrapper
            display flex
        .childSalesVolume-w
            flex 1
            width 30%
            position relative
            .echarts-position-div
                position: absolute;
                top 40%
                left 61%
                transform translate(-50%, 0)
                z-index: 9;
                text-align center
                font-family QuartzLTStd
                width 1.3rem
        .one-echarts-right
            // position absolute
            // right 1rem
            // top 50%
            // transform translateY(-50%)
            flex 2
            width 70%
            .one-echarts-right-header
                font-size .16rem
                color #333333
                display flex
                p
                    width .8rem
                span
                    font-family QuartzLTStd
                    font-size .28rem
            .one-echarts-right-body
                overflow hidden
                >div
                    display flex
                    margin-top .08rem
                    float left
                    width 33.33%
                    &:nth-child(1) p
                        background-image url(./images/bg1.png)
                    &:nth-child(2) p
                        background-image url(./images/bg2.png)
                    &:nth-child(3) p
                        background-image url(./images/bg3.png)
                    &:nth-child(4) p
                        background-image url(./images/bg4.png)
                    span
                        width .8rem
                        color #656565
                        font-size .16rem
                        display flex
                        align-items center
                    p
                        font-size .28rem
                        font-family QuartzLTStd
                        background-size cover
                        width 1.4rem
                        background-size 100% 100%
                        height .4rem
                        padding-left .1rem
                        padding-top .05rem
                        white-space nowrap
                        word-wrap normal
                        overflow hidden
                        text-overflow ellipsis
                        background-image url(./images/bg4.png)
    .clue-statistics-two .el-table
        width 100%
        margin-top .1rem
        .el-table__footer-wrapper tbody tr td div
            color #C9661E !important
        .el-table__header-wrapper thead tr th
            background #EDF0F5
            color #022782
        th, td
                padding .05rem 0
                text-align center
                div
                    font-size .16rem
            .el-table__footer tbody tr
                td
                    background none
                    div
                        color #823802
                td:first-child
                    background #F5F2ED
                    div
                        font-weight 800
</style>